/* {} */
.container {
    width: 95vw;
    /* padding: 15px; */
    border-radius: 15px;
    position: relative;
    /* margin: 10px; */
    margin-top: 20px;
    /* padding: 10px; */
    background: #D0FFD2;
    min-height: 300px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-top: 400px; */
}

.toggle {
    margin: 20px;
    width: 90vw;
    background: #fcfcfc7e;
    display: flex;
    justify-content: space-around;
    border-radius: 15px;
}

.disable {
    background-color: #ffffff00;
    font-size: 18px;
    padding: 5px 50px;
    border: none;
    border-radius: 20px;
    color: #939393;
}

.active {
    background-color: #ffffff;
    /* width: 48vw; */
    box-shadow: 0px 1px 5px #09af11;
    font-size: 18px;
    padding: 5px 50px;
    border: none;
    border-radius: 20px;
    color: #09af11;
}

.user {
    display: flex;
    justify-content: space-between;
    width: 90vw;
    align-items: center;
    text-decoration: none;
    color: black;
    padding: 10px;
}

.profile {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.name {
    font-weight: 500;
    /* color: #09af11; */
}

.role {
    color: #939393;
    font-size: 16px;
}