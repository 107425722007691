.container {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.header {
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
}

.img {
    width: 50vw;
    height: 150px;
    border-radius: 8px;
}

.label {
    color: #939393;
    font-size: 14px;
}