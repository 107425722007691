.card {
    border-radius: 15px;
    height: max-content;
    padding: 10px;
    background: #D0FFD2;
    width: 280px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.header {
    display: flex;
    gap: 10px;
    justify-content: start;
    align-items: end;
    border-bottom: 1px solid #d3d3d3;
    padding: 5px;
}

.desc {
    width: 280px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.desc p {
    width: 380px;
}

/* {} */