.container {
    padding: 40px 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.profile {
    width: 70px;
    height: 70px;
    border-radius: 50%;
}

.social_medias {
    display: grid;
    grid-template-columns: auto auto auto auto;
    width: 80vw;
    justify-content: space-between;
}

.name {
    font-weight: 500;
    /* color: #09af11; */
}

.role {
    color: #939393;
    font-size: 16px;
}

.icons {
    display: grid;
    grid-template-columns: auto auto;
    width: 80vw;
    justify-content: space-between;
}

.icon {
    width: 50px;
    height: 50px;
}

.star {
    width: 30px;
    height: 30px;
}