.container {
    display: flex;
    align-items: center;
    justify-content: space-between; 
    background-color: #3A994F;
    color: white;
    padding: 20px 10px;
}

.container h3 {
    /* padding-left: 10px; */
    letter-spacing: 0.5px;
}

/* .icon {
    padding-right: 10px;
} */