body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: #3A994F;
  font-weight: bold;
}

label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

label p {
  font-size: 12px;
  /* width: 50px; */
}

textarea {
  width: 80vw;
  border: none;
  background: #e9e9e9;
  border-radius: 15px;
  padding: 10px;
}

select {
  border: none;
  border-radius: 8px;
  font-size: 18px;
  background: #D0FFD2;
  padding: 15px;
  width: 81vw;
}

input {
  padding: 10px;
  font-size: 18px;
  border: none;
  border-radius: 8px;
  background: #87ffa151;
  border: 1px solid #3A994F;
  width: 80vw;
}

label {
  display: flex;
  justify-content: start;
  align-items: start;
  gap: 5px;
  margin: 10px;
}

button {
  text-decoration: none;
  width: max-content;
  align-self: center;
  text-align: center;
  padding: 10px 60px;
  font-size: 18px;
  border: none;
  background-color: #D0FFD2;
  border-radius: 8px;
  box-shadow: 0px 1px 6px #d3d3d3;
  color: #3A994F;
  font-weight: bold;
}

.text {
  text-decoration: none;
  font-size: 16px;
  padding: 10px;
}

.button {
  text-decoration: none;
  width: min-content;
  align-self: center;
  text-align: center;
  padding: 10px 60px;
  font-size: 18px;
  border: none;
  background-color: #D0FFD2;
  border-radius: 8px;
  box-shadow: 0px 1px 6px #d3d3d3;
  color: #3A994F;
  font-weight: bold;
}

.white-button {
  text-decoration: none;
  width: max-content;
  align-self: center;
  align-items: center;
  text-align: center;
  /* padding: 10px 60px; */
  font-size: 18px;
  border: none;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 1px 6px #d3d3d3;
  color: #3A994F;
  font-weight: bold;
}

.logo {
  font-size: 40px;
  color: #3A994F;
}

.blur_container {
  top: 0;
  left: 0;
  width: 99.2vw;
  min-height: 100vh;
  height: 150vh;
  max-height: max-content;
  backdrop-filter: blur(10px);
  position: absolute;
  background-color: #ffffff21;
  display: flex;
  justify-content: center;
  align-items: start;
  z-index: 2000;
  /* padding: 50px; */
}

.modal {
  z-index: 2000;
  margin: 100px;
  padding: 50px;
  width: 40vw;
  height: max-content;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 1px 10px #939393;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: start;
  gap: 30px;
}

.two-line {
  width: 40vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.line-start {
  display: flex;
  align-items: center;
  justify-content: start;
}

.line {
  display: flex;
  align-items: center;
  justify-content: center;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  gap: 10px;
}

.scroll-row {
  position: relative;
  overflow-x: auto;
  height: 380px;
  /* width: 100vw; */
  padding-right: 50px;
  /* padding: 10px; */
}

.scroll-column {
  position: relative;
  overflow-y: auto;
  height: 250px;
}

.scroll-normal-row {
  position: relative;
  overflow-x: auto;
  /* display: flex; */
  /* width: 100vw; */
}

.normal-row {
  display: flex;
  gap: 20px;
  /* position: absolute; */
  padding: 15px 5px;
}

.row {
  display: flex;
  /* overflow-x: auto; */
  gap: 300px;
  /* max-width: 98vw; */
  margin-right: 100px;
  padding-right: 500px;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.comment {
  background: #f0f0f0;
  padding: 10px;
  border-radius: 8px;
}

.divider {
  height: 1;
  border: 1px solid #d3d3d3;
  width: 40vw;
}

.loader-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 100px;
  aspect-ratio: 1;
  padding: 10px;
  box-sizing: border-box;
  display: grid;
  background: #fff;
  filter: blur(5px) contrast(10) hue-rotate(180deg);
  mix-blend-mode: darken;
}

.loader:before,
.loader:after {
  content: "";
  grid-area: 1/1;
  margin: 30px 0;
  border-radius: 100px;
  background: #ff00ff;
  animation: l4 2s infinite linear;
}

.loader:after {
  --s: -1;
}

@keyframes l4 {
  100% {
    transform: rotate(calc(var(--s, 1)*1turn))
  }
}

/* HTML: <div class="loader"></div> */
.rating-loader {
  --s: 40px;
  height: calc(var(--s)*0.9);
  width: calc(var(--s)*5);
  --v1: transparent, #000 0.5deg 108deg, #0000 109deg;
  --v2: transparent, #000 0.5deg 36deg, #0000 37deg;
  -webkit-mask:
    conic-gradient(from 54deg at calc(var(--s)*0.68) calc(var(--s)*0.57), var(--v1)),
    conic-gradient(from 90deg at calc(var(--s)*0.02) calc(var(--s)*0.35), var(--v2)),
    conic-gradient(from 126deg at calc(var(--s)*0.5) calc(var(--s)*0.7), var(--v1)),
    conic-gradient(from 162deg at calc(var(--s)*0.5) 0, var(--v2));
  -webkit-mask-size: var(--s) var(--s);
  -webkit-mask-composite: xor, destination-over;
  mask-composite: exclude, add;
  -webkit-mask-repeat: repeat-x;
  background: linear-gradient(#ffb940 0 0) left/0% 100% #ddd no-repeat;
  animation: l20 2s infinite linear;
}

@keyframes l20 {

  90%,
  100% {
    background-size: 100% 100%
  }
}

@media only screen and (max-width: 600px) {
  input {
    width: 65vw;
  }

  .abs {
    padding: 0;
  }

  .blur_container {
    padding: 0;
  }

  .modal {
    width: 70vw;
    margin: 100px 0;
  }

  .two-line {
    width: 70vw;
  }

  .button {
    font-size: 18px;
  }
}