.img {
    width: 250px;
    height: 350px;
    border-radius: 8px;
    /* background: #333; */
    filter: brightness(70%);
    margin: 10px;
    position: absolute;
}

.title {
    position: absolute;
    /* z-index: 1; */
    margin: 0;
    /* display: flex; */
    margin: 20px;
    margin-top: 300px;
    z-index: 2;
    color: white;
}

.title p {
    font-size: 12px;
}
